import { faCropSimple } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PhotoIcon } from "@heroicons/react/24/outline";
import {
  ArrowRightIcon,
  ArrowUpTrayIcon,
  PencilIcon,
  ScissorsIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import Spinner from "@sid/core/components/icon/Spinner";
import iconLists, { getIconByKey } from "@sid/core/components/microsite/icons";
import api from "@sid/core/util/api";
import resizeBeforeUpload from "@sid/core/util/image";
import assetUrl from "@sid/core/vars/assetUrl";
import axios from "axios";
import imageCompression from "browser-image-compression";
import clsx from "clsx";
import { Formik } from "formik";
import { Trans, useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import {
  CSSProperties,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import useSWR from "swr";
import Button from "./Button";
import { FieldInput } from "./Input";
import ModalComponent, { ConfirmModal, useModal } from "./Modal";
import Tab from "./Tab";

const EmojiPicker = dynamic(() => import("../EmojiPicker"), { ssr: false });
const Emoji = dynamic(() => import("@sid/core/components/Emoji"));

interface Props {
  id: string;
  value: string;
  square?: boolean;
  small?: boolean;
  withIcon?: boolean;
  withAsset?: boolean;
  onChange(value: string | null, extras?: any);
  imageStyle?: CSSProperties;
  uploadPath?: string;
  withCrop?: boolean;
  disabled?: boolean;
  maxWidth?: number;
  noGeneral?: boolean;
  allowSVG?: boolean;
  forceSquare?: boolean;
  forceAspect?: number | null;
  inMicrosite?: boolean;
}

const UploaderInner = (props) => {
  return (
    <div
      {...props}
      className={clsx(
        "bg-gray-200 dark:bg-gray-800 dark:bg-opacity-70 absolute top-0 w-full h-full",
        props.className
      )}
    />
  );
};

const ModifyButton = (props) => {
  return (
    <button
      {...props}
      className={clsx(
        "p-2 bg-black text-white rounded-md ml-2",
        "bg-opacity-50",
        "transition-colors duration-150",
        "w-9 h-9",
        "hover:bg-opacity-90",
        props.className
      )}
    />
  );
};

const IconButtonContainer = ({ fullWidth = false, ...props }) => {
  return (
    <div
      {...props}
      className={clsx("w-full relative", !fullWidth && "pb-[100%]")}
    />
  );
};

const IconButton = ({ fullWidth = false, ...props }) => {
  return (
    <button
      {...props}
      className={clsx(
        !fullWidth && `absolute justify-center`,
        fullWidth && `justify-start`,
        `left-0 top-0 bg-white dark:bg-gray-600 rounded-lg w-full h-full flex items-center appearance-none text-gray-600 dark:text-gray-200 border dark:border-gray-500 transition-all duration-200 hover:ring-4 hover:ring-red-200 dark:hover:ring-opacity-20 hover:border-red-500`,
        props.className
      )}
    />
  );
};

const ImageChooserContainer = ({ square, small, ...props }) => {
  return (
    <button
      {...props}
      className={clsx(
        `relative dark:text-white block w-full`,
        square ? `pb-[100%]` : `pb-[50%]`,
        small && `text-xs`
      )}
    />
  );
};

const ImageIconChooser = ({
  close,
  onChoose,
  getRootProps,
  noGeneral,
  inputRef,
  withAsset,
  withIcon,
  chooserState,
  isDragActive,
  status,
}) => {
  const { t } = useTranslation();
  const [panel, setPanel] = useState<"icon" | "upload">(
    withAsset ? "upload" : "icon"
  );
  const icons = useSWR("user/microsites/utils/icons");
  const [section, setSection] = useState(0);

  const sections = ["Emoji", "Brand", [...(noGeneral ? [] : ["General"])]];

  const customBrandIcons = useMemo(() => {
    if (icons.data?.data) {
      return icons.data.data;
    }
    return [];
  }, [icons.data]);

  return (
    <div>
      {withIcon && (
        <Tab.Container>
          <Tab.Item active={panel === "icon"} onClick={() => setPanel("icon")}>
            {t("dashboard:choose_icon")}
          </Tab.Item>
          <Tab.Item
            active={panel === "upload"}
            onClick={() => setPanel("upload")}
          >
            {t("upload_image")}
          </Tab.Item>
        </Tab.Container>
      )}
      <div className="my-3">
        {panel === "icon" && (
          <div>
            <div className="flex gap-2 mb-3">
              {sections.map((item, index) => (
                <button
                  key={index}
                  onClick={() => setSection(index)}
                  className={clsx(
                    "py-1 px-3 text-sm font-semibold transition-all",
                    {
                      "bg-gray-100 dark:bg-gray-800 rounded": index === section,
                      "text-gray-500 dark:text-gray-400": index !== section,
                    }
                  )}
                >
                  {item}
                </button>
              ))}
            </div>

            {section === 0 && (
              <div>
                <EmojiPicker
                  onEmojiSelect={(value) => {
                    onChoose("emoji:" + value.shortcodes);
                  }}
                />
              </div>
            )}

            {section === 1 && (
              <Formik
                initialValues={{ q: "" }}
                onSubmit={() => {
                  //...
                }}
              >
                {({ values }) => (
                  <div>
                    <div className="mb-2">
                      <FieldInput
                        type="text"
                        name="q"
                        className="w-full"
                        placeholder={t("search")}
                        inputSize="lg"
                      />
                    </div>

                    <div className="w-full h-[300px] overflow-y-auto bg-gray-100 dark:bg-gray-800 p-1">
                      <div className="flex items-start justify-start flex-wrap">
                        {!icons.data && icons.isValidating ? (
                          <div>Loading...</div>
                        ) : (
                          customBrandIcons
                            .filter((item) => {
                              return item.name.toLowerCase().includes(values.q);
                            })
                            .map((item, index) => (
                              <div
                                className="w-full lg:w-6/12 p-1"
                                key={item.name + "-" + index}
                              >
                                <div className="w-full h-12">
                                  <IconButtonContainer fullWidth>
                                    <IconButton
                                      onClick={() =>
                                        onChoose(item.slug, item.color)
                                      }
                                      fullWidth
                                    >
                                      <img
                                        src={assetUrl + item.slug}
                                        alt={item.name}
                                        className="w-12 mr-2"
                                      />
                                      <span className="text-sm">
                                        {item.name}
                                      </span>
                                    </IconButton>
                                  </IconButtonContainer>
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            )}

            {section === 2 && (
              <Formik
                initialValues={{ q: "" }}
                onSubmit={() => {
                  //...
                }}
              >
                {({ values }) => (
                  <div>
                    <div className="mb-2">
                      <FieldInput
                        type="text"
                        name="q"
                        className="w-full"
                        placeholder={t("search")}
                      />
                    </div>

                    <div className="w-full h-[300px] overflow-y-auto bg-gray-100 dark:bg-gray-800 p-1">
                      <div className="flex items-start justify-start flex-wrap">
                        {iconLists
                          .filter((item) => {
                            return item.name.includes(values.q);
                          })
                          .map((item, index) => (
                            <div
                              className="w-2/12 p-1"
                              key={item.name + "-" + index}
                            >
                              <IconButtonContainer>
                                <IconButton
                                  onClick={() => onChoose("icon:" + item.name)}
                                >
                                  <FontAwesomeIcon
                                    icon={item.icon as any}
                                    className="w-5 h-5"
                                  />
                                </IconButton>
                              </IconButtonContainer>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            )}
          </div>
        )}
        {panel === "upload" && (
          <Fragment>
            <button
              {...getRootProps({ className: "dropzone" })}
              className="flex flex-col items-center justify-center w-full h-full px-6 py-10 bg-gray-100 dark:bg-gray-800 rounded-md relative"
              onClick={() => inputRef.current?.click()}
              disabled={status === "uploading"}
            >
              {isDragActive && (
                <div className="pointer-events-none absolute inset-0 m-3 border-2 border-dashed border-slate-400 border-opacity-30 rounded-md bg-white bg-opacity-10" />
              )}
              {status === "uploading" ? (
                <Spinner className="w-6 animate-spin" />
              ) : (
                <ArrowUpTrayIcon className="w-6" />
              )}
              <p className="text-sm">{t("dashboard:choose_image_text")}</p>
            </button>
            {withAsset && <MediaBrowser />}
          </Fragment>
        )}
      </div>
      <div className="flex justify-end mt-4">
        <Button color="secondary" onClick={close}>
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
};

const MediaBrowser = () => {
  return (
    <div className="h-[400px] bg-gray-100 border rounded-md mt-3 flex flex-wrap overflow-y-auto sid-scrollbar">
      {[...new Array(6)].map((_, i) => (
        <div className="w-6/12 p-1" key={i}>
          <div className="pb-[100%] bg-white rounded-md"></div>
        </div>
      ))}
      <div className="w-full p-1">
        <Button className="w-full">Load more</Button>
      </div>
    </div>
  );
};

const ImageChooser = ({
  id,
  value,
  onChange,
  square,
  small,
  withIcon,
  withAsset,
  imageStyle = {},
  uploadPath,
  withCrop,
  disabled,
  maxWidth,
  noGeneral,
  forceSquare = false,
  allowSVG = false,
  inMicrosite,
}: Props) => {
  const chooserState = useImageChooser({
    allowSVG,
    disabled,
    maxWidth,
    noGeneral,
    onChange,
    uploadPath,
    withCrop,
    id,
    value,
    imageStyle,
    small,
    square,
    withIcon,
    withAsset,
    forceSquare,
  });

  return (
    <Fragment>
      <ImageChooserContainer square={square} small={small}>
        <UploaderInner>
          {chooserState.status === "idle" && !value ? (
            <ChooseContainer
              chooserState={chooserState}
              disabled={disabled}
              withChooser={withIcon || withAsset}
              withIcon={withIcon}
            />
          ) : (
            <Fragment />
          )}
          {chooserState.status === "idle" && value ? (
            <ImageContainer
              chooserState={chooserState}
              disabled={disabled}
              imageStyle={imageStyle}
              value={value}
              withIcon={withIcon}
            />
          ) : (
            <Fragment />
          )}
          {chooserState.status === "uploading" && <LoadingContainer />}
          <input
            id={id}
            {...chooserState.getInputProps()}
            onChange={(e) => {
              const inputProp = chooserState.getInputProps();
              if (inputProp && typeof inputProp.onChange !== "undefined") {
                inputProp.onChange(e);
              }
              e.target.value = "";
            }}
            accept="image/png, image/jpg, image/jpeg"
            className="hidden"
          />
        </UploaderInner>
      </ImageChooserContainer>
      <ImageChooserExtras {...chooserState} />
    </Fragment>
  );
};

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
  zoomLevel: number
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: zoomLevel,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

export const useImageChooser = ({
  disabled,
  allowSVG = false,
  withCrop = false,
  maxWidth,
  uploadPath = "/user/microsite/upload",
  onChange,
  noGeneral,
  value,
  forceSquare = false,
  forceAspect = null,
  withAsset,
  withIcon,
}: Props) => {
  const {
    getRootProps,
    getInputProps,
    inputRef,
    isDragActive,
    open: openUpload,
  } = useDropzone({
    multiple: false,
    noClick: true,
    disabled,
    onDrop: (files) => {
      _onChange(files);
      if (inputRef.current) {
      }
    },
  });
  const chooserModal = useModal();
  const deleteModal = useModal();
  const cropModal = useModal();
  const { t } = useTranslation();
  const [status, setStatus] = useState<"idle" | "uploading">("idle");
  const [isFocus, setIsFocus] = useState(false);
  const [imgSrc, setImgSrc] = useState<string>();
  const [aspect, setAspect] = useState<any>(undefined);
  const [selectedFile, setSelectedFile] = useState<File>();
  const imageCropRef = useRef<HTMLImageElement>();
  const [crop, setCrop] = useState<Crop>();

  const _onChange = (files: File[]) => {
    const file = files.length > 0 ? files[0] : null;

    if (file) {
      if (!allowSVG && !/^image\/(png|jpeg|jpg)/.test(file.type)) {
        toast.error("Unsupported file type");
        return;
      }

      if (!allowSVG && withCrop) {
        setStatus("uploading");
        imageCompression(files[0], {
          maxSizeMB: 0.9,
          maxWidthOrHeight: maxWidth,
          useWebWorker: true,
        })
          .then((file) => {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              setImgSrc(reader.result?.toString() || "");
              chooserModal.close();
              cropModal.setOpen(true);
              setTimeout(() => {
                if (forceSquare || forceAspect) {
                  setAspect(forceAspect || 1);
                  //@ts-ignore
                  const { width, height } = imageCropRef.current;
                  setCrop(
                    centerAspectCrop(
                      width,
                      height,
                      forceAspect || 1,
                      forceAspect ? 100 : 50
                    )
                  );
                } else {
                  setAspect(undefined);
                  setCrop(undefined);
                }
              }, 100);
            });
            reader.readAsDataURL(file);
          })
          .finally(() => {
            setStatus("idle");
          });
        return;
      }
      chooserModal.close();
      setStatus("uploading");
      onUpload(file);
    }
  };

  const onUpload = async (file: File, isCropping = false) => {
    const formData = new FormData();
    try {
      if (!allowSVG) {
        if (crop?.unit === "%") {
          crop.x = (crop.x / 100) * (imageCropRef.current?.width || 0);
          crop.y = (crop.y / 100) * (imageCropRef.current?.height || 0);
          crop.width = (crop.width / 100) * (imageCropRef.current?.width || 0);
          crop.height =
            (crop.height / 100) * (imageCropRef.current?.height || 0);
        }

        const resized = await resizeBeforeUpload(
          file,
          isCropping,
          imageCropRef.current?.width,
          imageCropRef.current?.height,
          crop as any,
          maxWidth,
          maxWidth
        );

        formData.append("file", resized);
      } else {
        formData.append("file", file);
      }
      //@ts-ignore
      const { data } = await api().post(uploadPath, formData);
      onChange(data?.data.filename);
      toast.success(t("dashboard:file_has_been_uploaded"));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 413) {
          toast.error(t("image_size_exceeded"));
        } else {
          toast.error(
            e.response?.data?.message || t("dashboard:file_error_uploaded")
          );
        }
      }
    } finally {
      setStatus("idle");
    }
  };

  const onDelete = () => {
    deleteModal.close();
    onChange(null);
  };

  const onChoose = (name, extras) => {
    chooserModal.close();
    onChange(name, extras);
  };

  //@ts-ignore
  const isCropping = crop?.width > 0 && crop?.width > 0;

  const doCrop = () => {
    if (selectedFile) {
      cropModal.close();
      chooserModal.close();
      setStatus("uploading");
      if (isCropping) {
        onUpload(selectedFile, true);
      } else {
        onUpload(selectedFile);
      }
    }
  };

  useEffect(() => {
    const onPasteSomething = (e) => {
      if (e.clipboardData.files.length > 0 && isFocus) {
        if (disabled) return;
        _onChange(e.clipboardData.files);
      }
    };

    window.addEventListener("paste", onPasteSomething);
    return () => {
      window.removeEventListener("paste", onPasteSomething);
    };
  }, [isFocus, disabled]);

  return {
    aspect,
    chooserModal,
    crop,
    cropModal,
    deleteModal,
    doCrop,
    getInputProps,
    getRootProps,
    imageCropRef,
    imgSrc,
    inputRef,
    isCropping,
    isDragActive,
    isFocus,
    noGeneral,
    onChoose,
    onDelete,
    openUpload,
    setAspect,
    setCrop,
    setIsFocus,
    status,
    value,
    forceSquare,
    forceAspect,
    withAsset,
    withIcon,
  };
};

export const ImageChooserExtras = ({
  aspect,
  chooserModal,
  crop,
  cropModal,
  deleteModal,
  doCrop,
  getRootProps,
  imageCropRef,
  imgSrc,
  inputRef,
  isCropping,
  noGeneral,
  onChoose,
  onDelete,
  setAspect,
  setCrop,
  forceSquare,
  forceAspect,
  withAsset,
  withIcon,
  isDragActive,
  status,
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <ModalComponent {...chooserModal} disabled>
        <h1 className="font-semibold text-lg mb-2 font-montserrat">
          {!withAsset ? (
            <Trans i18nKey="dashboard:image_chooser_title">Choose Image</Trans>
          ) : (
            <Trans i18nKey="image_chooser_or_upload">
              Upload or Choose image
            </Trans>
          )}
        </h1>
        <div>
          <ImageIconChooser
            {...chooserModal}
            getRootProps={getRootProps}
            isDragActive={isDragActive}
            onChoose={onChoose}
            noGeneral={noGeneral}
            inputRef={inputRef}
            withAsset={withAsset}
            withIcon={withIcon}
            status={status}
          />
        </div>
      </ModalComponent>
      <ConfirmModal
        modalProp={deleteModal}
        content={t("confirm_delete_image_description")}
        title={t("confirm_delete_image")}
        onOk={onDelete}
      />
      <ModalComponent {...cropModal}>
        <h1 className="font-semibold text-lg mb-2 -mt-1">
          <FontAwesomeIcon
            icon={faCropSimple}
            className="w-5 inline-block mr-2"
          />
          <Trans i18nKey="dashboard:crop_image">Crop Image</Trans>
        </h1>

        <button className="absolute top-3 right-3" onClick={cropModal.close}>
          <XMarkIcon className="w-7" />
        </button>

        <div className="-mx-4">
          <div className="mb-2">
            <div className="overflow-hidden bg-slate-100 flex justify-center items-center">
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                aspect={aspect}
                keepSelection={forceSquare || forceAspect}
                className="flex mx-auto max-h-[calc(100dvh-12rem)]"
              >
                <img
                  src={imgSrc}
                  ref={imageCropRef as any}
                  alt="cropped-img"
                  className=""
                />
              </ReactCrop>
            </div>
            {!(forceSquare || forceAspect) && (
              <div className="flex items-center font-semibold gap-2 bg-black text-white text-sm p-2">
                <div>
                  <Trans i18nKey="dashboard:aspect">Aspect</Trans>
                </div>
                <button
                  color="secondary"
                  className={clsx("border px-2 py-1 rounded-md", {
                    "bg-white text-black": aspect === 1,
                  })}
                  type="button"
                  onClick={() => {
                    setAspect(1);
                    //@ts-ignore
                    const { width, height } = imageCropRef.current;
                    setCrop(centerAspectCrop(width, height, 1, 75));
                  }}
                >
                  <Trans i18nKey="square">Square</Trans>
                </button>
                <button
                  color="secondary"
                  className={clsx("border px-2 py-1 rounded-md", {
                    "bg-white text-black": !aspect,
                  })}
                  type="button"
                  onClick={() => setAspect(undefined)}
                >
                  <Trans i18nKey="dashboard:freeform">Freeform</Trans>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-4">
          {isCropping && !(forceSquare || forceAspect) ? (
            <Button
              color="secondary"
              className="w-full"
              onClick={() => {
                setCrop(undefined);
              }}
            >
              <XMarkIcon className="w-5 inline-block -mt-1" />{" "}
              <Trans i18nKey="dashboard:remove_crop">Remove Crop</Trans>
            </Button>
          ) : (
            <Fragment />
          )}
          <Button color="blue" className="w-full !touch-auto" onClick={doCrop}>
            {isCropping ? (
              <>
                <ScissorsIcon className="w-5 inline-block -mt-1" />{" "}
                <Trans i18nKey="dashboard:crop">Crop</Trans>
              </>
            ) : (
              <>
                <Trans i18nKey="dashboard:continue_without_crop">
                  Continue Without Crop
                </Trans>{" "}
                <ArrowRightIcon className="ml-2 w-5 inline-block" />
              </>
            )}
          </Button>
        </div>
      </ModalComponent>
    </Fragment>
  );
};

const ChooseContainer = ({ chooserState, disabled, withIcon, withChooser }) => {
  return (
    <div
      {...chooserState.getRootProps({ className: "dropzone" })}
      className={clsx(
        "flex items-center w-full h-full p-2",
        chooserState.isDragActive && "bg-blue-100 dark:bg-blue-700"
      )}
      tabIndex={0}
      onFocus={() => {
        chooserState.setIsFocus(true);
      }}
      onBlur={() => {
        chooserState.setIsFocus(false);
      }}
    >
      {chooserState.isDragActive && (
        <div className="pointer-events-none absolute inset-0 m-3 border-2 border-dashed border-slate-400 border-opacity-30 rounded-md bg-white bg-opacity-10" />
      )}
      <button
        onClick={(e) => {
          e.preventDefault();
          if (disabled) return;
          if (withChooser) {
            chooserState.chooserModal.setOpen(true);
          } else {
            chooserState.inputRef.current?.click();
          }
        }}
        className="flex flex-col w-full items-center dropzoned lg:p-10"
      >
        <PhotoIcon className="w-6 h-6" />
        <p>
          {withIcon ? (
            <Trans i18nKey="dashboard:choose_image_icon_text" />
          ) : (
            <Trans i18nKey="dashboard:choose_image_text" />
          )}
        </p>
      </button>
    </div>
  );
};

const LoadingContainer = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Spinner className="w-5 animate-spin mb-2" />
      <p>{t("uploading")}</p>
    </div>
  );
};

const ImageContainer = ({
  chooserState,
  withIcon,
  value,
  imageStyle,
  disabled,
}) => {
  return (
    <div
      className="relative h-full w-full flex items-center justify-center"
      tabIndex={0}
      onFocus={() => {
        chooserState.setIsFocus(true);
      }}
      onBlur={() => {
        chooserState.setIsFocus(false);
      }}
    >
      {withIcon && value?.startsWith("icon:") ? (
        <FontAwesomeIcon
          icon={getIconByKey(value)?.icon as any}
          className="text-4xl"
        />
      ) : withIcon && value?.startsWith("emoji:") ? (
        <Emoji id={value.split("emoji:")[1]} size="3.5em" />
      ) : (
        <div
          className="w-full h-full bg-contain bg-center bg-no-repeat"
          style={{
            ...imageStyle,
            backgroundImage: `url("${assetUrl + value}")`,
          }}
        />
      )}
      {chooserState.isDragActive && (
        <div className="pointer-events-none absolute inset-0 m-3 border-2 border-dashed border-slate-400 border-opacity-30 rounded-md bg-white bg-opacity-10" />
      )}
      <div
        {...chooserState.getRootProps({
          className: "dropzone",
        })}
        className="absolute top-0 left-0 w-full h-full pr-2 pb-2 hover:opacity-100 opacity-0 transition-all duration-150 flex items-end justify-end !cursor-auto"
        onClick={(e) => e.preventDefault()}
      >
        <ModifyButton onClick={chooserState.deleteModal.toggle}>
          <TrashIcon className="w-5" />
        </ModifyButton>
        <ModifyButton
          {...chooserState.getRootProps({
            className: "dropzone",
          })}
          onClick={() => {
            if (disabled) return;

            if (withIcon) {
              chooserState.chooserModal.open();
            } else {
              chooserState.openUpload();
            }
          }}
        >
          <PencilIcon className="w-5" />
        </ModifyButton>
      </div>
    </div>
  );
};

export default ImageChooser;
